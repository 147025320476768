import Vimeo from '@u-wave/react-vimeo';

import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Player from '@vimeo/player';
import { Button } from 'src/components/Button';
import { LoaderComponent } from 'src/components/Loader';
import { Progress } from 'src/components/Progress';
import { TopNavbar } from 'src/components/TopNavbar';
import { useAuth } from 'src/hooks/useAuth';
import { RoutesEnum } from 'src/shared/enums';
import actions from 'src/store/actions';
import { trainingLoadRequest } from 'src/store/actions/training';
import {
  getCurrentExam,
  getCurrentTraining,
  getCurrentTrainingPeriod,
  getTrainingListingResponse,
} from 'src/store/selectors';
import {
  TrainingListingElement,
  TrainingSubmissionStatus,
} from 'src/store/types';
import { THIconGray, THIconPlayVideo, THShield } from 'src/theme/media';
import { TrainingCollapsiblePanel } from '../../components';
import * as S from './Styles';

// import Player from "@vimeo/player";

export const TrainingPage: FC<unknown> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useAuth();
  const [player, setPlayer] = useState<Player | undefined>(undefined);
  const [playerInitialized, setPlayerInitialized] = useState<boolean>();
  const [quizEnabled, setQuizEnabled] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const trainingListing = useSelector(getTrainingListingResponse);
  const currentTraining = useSelector(getCurrentTraining);
  const currentExam = useSelector(getCurrentExam);
  const currentTrainingPeriod = useSelector(getCurrentTrainingPeriod);

  useEffect(() => {
    if (
      currentExam &&
      currentTraining &&
      player &&
      trainingListing.records.length > 0
    ) {
      setLoaded(true);
    }
  }, [currentExam, currentTraining, player, trainingListing]);

  useEffect(() => {
    dispatch(actions.trainingPeriodCompanyLoadRequest());
    dispatch(actions.userTrainingListingLoadRequest());
    dispatch(actions.examLoadRequest());
  }, []);

  useEffect(() => {
    if (currentTraining) {
      setPlayerInitialized(false);
      setQuizEnabled(false);
    }
  }, [currentTraining]);

  const completedCount = useMemo(() => {
    return trainingListing.records.reduce(
      (count: number, training: TrainingListingElement) => {
        return (
          count +
          (training.lastSubmissionStatus === TrainingSubmissionStatus.PASSED
            ? 1
            : 0)
        );
      },
      0,
    );
  }, [trainingListing]);

  const trainingListingMap = useMemo(() => {
    return trainingListing.records.reduce(
      (
        map: Map<number, TrainingListingElement>,
        training: TrainingListingElement,
      ) => {
        map.set(training.id, training);

        return map;
      },
      new Map<number, TrainingListingElement>(),
    );
  }, [trainingListing]);

  const completedPercent = Math.round(
    (completedCount * 100) / trainingListing.total,
  );

  const handlePlayOnClick = (): void => {
    if (player) {
      void player.play().then(() => setPlayerInitialized(true));
    }
  };

  const handleOnReadyEvent = (player: Player): void => {
    setPlayer(player);
  };

  const handleOnEndEvent = (): void => {
    setPlayerInitialized(false);
    setQuizEnabled(true);
  };

  const handleTakeQuizOnClick = (): void => {
    history.push(`${RoutesEnum.TRAINING_QUIZ}/${currentTraining.id}`);
  };

  const handleTakeExamOnClick = (): void => {
    history.push(`${RoutesEnum.EXAM}`);
  };

  const handleLoadTraining = (training: TrainingListingElement): void => {
    dispatch(trainingLoadRequest(training.id));
  };

  const handleGetTrainingCertificate = (): void => {
    dispatch(
      actions.downloadTrainingCertificateRequest(
        currentExam.lastSubmissionId || 0,
        user.name,
        currentExam.trainingTypeName || 'certificate',
      ),
    );
  };

  const isExamPending =
    currentExam?.lastSubmissionStatus !== TrainingSubmissionStatus.PASSED;

  const canTakeExam =
    (trainingListing.records.every(
      (training) =>
        training.lastSubmissionStatus === TrainingSubmissionStatus.PASSED,
    ) ||
      user.allowSkipTraining) &&
    isExamPending;

  const canTakeTrainingVideoQuiz =
    quizEnabled &&
    isExamPending &&
    (trainingListingMap.has(currentTraining.id)
      ? trainingListingMap.get(currentTraining.id)?.lastSubmissionStatus !==
        TrainingSubmissionStatus.PASSED
      : true);

  return (
    <>
      <LoaderComponent opacity={loaded ? 0 : 100} />
      <div style={{ opacity: loaded ? 100 : 0 }}>
        <TopNavbar
          title="Training"
          extraOptions={[
            currentExam?.lastSubmissionStatus === 'PASSED' && (
              <Button
                title={'Training Certificate'}
                onClick={handleGetTrainingCertificate}
              />
            ),
          ]}
        />

        <S.ProgressSectionContainer>
          <S.ProgressBarWrapper>
            <div>My progress</div>
            <Progress percent={completedPercent} />
          </S.ProgressBarWrapper>
          {currentTrainingPeriod && (
            <S.DueDateContainer>
              <div>My due date:</div>
              <div>{moment(currentTrainingPeriod.dueDate).format('LL')}</div>
            </S.DueDateContainer>
          )}
        </S.ProgressSectionContainer>

        <S.TrainingSectionContainer>
          <S.TrainingVideoContainer>
            <S.VimeoVideoWrapper>
              {currentTraining && (
                <Vimeo
                  onReady={handleOnReadyEvent}
                  video={currentTraining.videoId}
                  responsive
                  onEnd={handleOnEndEvent}
                />
              )}
              {!playerInitialized && (
                <S.VimeoVideoOverlay>
                  <img src={THIconPlayVideo} onClick={handlePlayOnClick} />
                </S.VimeoVideoOverlay>
              )}
            </S.VimeoVideoWrapper>
            <S.TrainingVideoInfoContainer>
              <S.TrainingVideoTitleContainer>
                <span>
                  <img height={55} width={55} src={THShield} />
                </span>
                <span>
                  <S.TrainingVideoTitle>
                    {currentTraining?.name}
                  </S.TrainingVideoTitle>
                  <S.TrainingVideoSubTitle>
                    <img src={THIconGray} />
                    TotalHIPAA
                  </S.TrainingVideoSubTitle>
                </span>
              </S.TrainingVideoTitleContainer>
              <S.TrainingButtonsContainer>
                {canTakeExam && (
                  <Button
                    title={'Take final exam'}
                    onClick={handleTakeExamOnClick}
                  />
                )}
                {canTakeTrainingVideoQuiz && (
                  <Button title={'Take quiz'} onClick={handleTakeQuizOnClick} />
                )}
              </S.TrainingButtonsContainer>
            </S.TrainingVideoInfoContainer>
          </S.TrainingVideoContainer>
          <S.TrainingModulesContainer>
            <TrainingCollapsiblePanel
              handlePlayVideo={handlePlayOnClick}
              trainingList={trainingListing}
              handleLoadTraining={handleLoadTraining}
            />
          </S.TrainingModulesContainer>
        </S.TrainingSectionContainer>
      </div>
    </>
  );
};
