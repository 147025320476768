import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';

import { TopNavbar } from 'src/components/TopNavbar';
import { Button } from 'src/components/Button';
import { Pagination } from 'src/components/Pagination';
import { RoutesEnum } from 'src/shared/enums';
import { SearchInput } from 'src/components/SearchInput';
import actions from 'src/store/actions';
import { TrainingTypesResponse } from 'src/store/types';
import {
  getCurrentExam,
  getExamListingLoader,
  getExamListingResponse,
  getExamLoader,
  getTrainingTypes,
} from 'src/store/selectors';

import { ExamConfigForm, ExamListingTable } from '../../components';

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

export const ExamListPage: FC<unknown> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const examLoader = useSelector(getExamLoader);
  const examListingLoader = useSelector(getExamListingLoader);
  const examListingResponse = useSelector(getExamListingResponse);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [creationModalVisible, setCreationModalVisible] = useState(false);
  const trainingTypes: TrainingTypesResponse = useSelector(getTrainingTypes);
  const currentExam = useSelector(getCurrentExam);

  useEffect(() => {
    if (examLoader && examLoader.completed && examLoader.success) {
      dispatch(actions.examListingLoadRequest(page, pageSize));
    }
  }, [examLoader]);

  useEffect(() => {
    if (creationModalVisible && currentExam) {
      setCreationModalVisible(false);
      history.push(`${RoutesEnum.EXAM_CONFIG}/${currentExam.id}`);
    }
  }, [currentExam]);

  useEffect(() => {
    dispatch(actions.examReset());
    dispatch(actions.trainingTypesLoadRequest());
  }, []);

  useEffect(() => {
    dispatch(actions.examListingLoadRequest(page, pageSize));
  }, [page, pageSize]);

  const debounceExamName = _.debounce((examName: string) => {
    dispatch(actions.examListingLoadRequest(page, pageSize, examName));
  }, 1000);
  return (
    <>
      <TopNavbar
        title="My exams"
        prevRoute={null}
        extraOptions={[
          <Button
            onClick={() => setCreationModalVisible(true)}
            title="Create new Exam"
            key={1}
          />,
        ]}
      />
      <PageContentContainer>
        <SearchInput
          onChange={(e) => debounceExamName(e.target.value)}
          placeholder="Exam name"
        />
        <ExamListingTable dataSource={examListingResponse.records} />
        <Pagination
          current={page}
          items={examListingLoader.loading ? 0 : examListingResponse.total}
          pageSize={pageSize}
          onPageChange={setPage}
          onPageSizeChange={(size) => {
            setPageSize(size);
            setPage(1);
          }}
          sizes={[10, 25, 50]}
        />
      </PageContentContainer>
      <Modal
        title="New exam"
        bodyStyle={{ backgroundColor: 'whitesmoke' }}
        visible={creationModalVisible}
        onCancel={() => setCreationModalVisible(false)}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <ExamConfigForm trainingTypes={trainingTypes} />
      </Modal>
    </>
  );
};
