import React, { FC } from 'react';
import { Form, Upload } from 'antd';
import { Button } from 'src/components/Button';
import { FileData } from 'src/store/types';
import { normalizeFile, uploadFile } from 'src/services/uploadFile';

export interface CommentAttachmentInputProps {
  onChange?: (value: any) => void;
  value?: string;
}

export const CommentAttachmentInput: FC<CommentAttachmentInputProps> = () => {
  const handleAttachmentsChange = (info: {
    file: FileData;
    fileList: FileData[];
  }) => {
    const { file, fileList } = info;
    if (file.status === 'removed') {
      return fileList.map((f) => normalizeFile(f));
    }
    if (file.status === 'done') {
      if (file.xhr) {
        const fileData = fileList.map((f) => normalizeFile(f));
        return fileData;
      }
      return fileList;
    }
    if (file.status === 'uploading') {
      return fileList;
    }
  };

  return (
    <Form.Item
      name="attachments"
      valuePropName="fileList"
      getValueFromEvent={handleAttachmentsChange}
      noStyle
    >
      <Upload
        className="special-form-item"
        customRequest={uploadFile as any}
        name="files"
        multiple
      >
        <Button variant="tertiary" altered title="Add Attachment" />
      </Upload>
    </Form.Item>
  );
};
