import { ShowErrorAction, User } from '.';

export const NOTIFICATION_UPDATE_REQUEST = 'NOTIFICATION_UPDATE_REQUEST';
export const NOTIFICATION_UPDATE_SUCCESS = 'NOTIFICATION_UPDATE_SUCCESS';
export const NOTIFICATION_UPDATE_ERROR = 'NOTIFICATION_UPDATE_ERROR';

export enum NotificationTypes {
  mention = 'MENTION',
  comment = 'NEW_COMMENT',
  documentDraftInClientReview = 'DOCUMENT_DRAFT_IN_CLIENT_REVIEW',
  templateNeedsReview = 'TEMPLATE_NEEDS_REVIEW',
  newAssignee = 'NEW_ASSIGNEE',
  newOwnerAssignee = 'NEW_OWNER_ASSIGNEE',
  riskAssessmentEnabled = 'RISK_ASSESSMENT_ENABLED',
  riskReportInClientReview = 'RISK_REPORT_IN_CLIENT_REVIEW',
  riskReportInAdminReview = 'RISK_REPORT_IN_ADMIN_REVIEW',
}

export interface NotificationFromResponse {
  id: number;
  createdAt: string;
  updatedAt: string;
  type: NotificationTypes;
  pending: boolean;
  archived: boolean;
  location: string;
  from: User;
  to: User;
  locationName: string;
}

export interface NotificationUpdateParams {
  id: number;
  pending?: boolean;
  archived?: boolean;
}

export interface NotificationUpdateRequestAction {
  type: typeof NOTIFICATION_UPDATE_REQUEST;
  notification: NotificationUpdateParams;
}

export interface NotificationUpdateSuccessAction {
  type: typeof NOTIFICATION_UPDATE_SUCCESS;
  notification: NotificationFromResponse;
}

export type NotificationUpdateErrorAction = ShowErrorAction<
  typeof NOTIFICATION_UPDATE_ERROR
>;

export type NotificationActionTypes =
  | NotificationUpdateRequestAction
  | NotificationUpdateSuccessAction
  | NotificationUpdateErrorAction;
