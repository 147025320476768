const symbolsWhitelist: string[] = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'.split(
  '',
);

export const minLength =
  (len: number) =>
  (text: string): boolean =>
    text.length > len;

export const maxLength =
  (len: number) =>
  (text: string): boolean =>
    text.length < len;

export const includesSymbol = (text: string): boolean => {
  const characters = text.split('');
  return (
    characters.length > 1 &&
    characters.reduce(
      (res: boolean, char: string) => res || symbolsWhitelist.includes(char),
      false,
    )
  );
};

export const includesNumber = (text: string): boolean => {
  const characters = text.split('');
  return (
    characters.length > 1 &&
    characters.reduce(
      (res: boolean, char: string) => res || Number.isInteger(Number(char)),
      false,
    )
  );
};

export const includesUppercase = (text: string): boolean => {
  const characters = text.split('');
  return (
    characters.length > 1 &&
    characters.reduce(
      (res: boolean, char: string) =>
        res ||
        (!Number.isInteger(Number(char)) &&
          !symbolsWhitelist.includes(char) &&
          char === char.toUpperCase()),
      false,
    )
  );
};

export const includesLowercase = (text: string): boolean => {
  const characters = text.split('');
  return (
    characters.length > 1 &&
    characters.reduce(
      (res: boolean, char: string) =>
        res ||
        (!Number.isInteger(Number(char)) &&
          !symbolsWhitelist.includes(char) &&
          char === char.toLowerCase()),
      false,
    )
  );
};

export const validate = (
  text: string,
  error: string | undefined,
  validator: (str: string) => boolean,
  cb: (error: string) => void,
): void => {
  if (!validator(text)) {
    cb(error || 'Failed validation');
  }
};
