import React, { FC, useEffect, useState } from 'react';
import { Checkbox, Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { TextInput } from 'src/components/TextInput';
import {
  CompanyUserRole,
  TypeOfTraining,
  UserInput,
  UserUpdateInput,
  TrainingTypesResponse,
} from 'src/store/types';
import { userSaveRequest, userUpdateRequest } from 'src/store/actions/user';
import { TYPE_OF_TRAINING_TEXTS } from 'src/constants/company';
import { TypeOfTrainingOption } from 'src/types/company';
import { UserRoleOption } from 'src/types/user';
import { mapUserRoleOptions } from 'src/shared/utils/user';

export interface FormCreateUserProps {
  userForm: FormInstance;
  companyId?: number;
  editingProfile?: boolean;
  editOther?: boolean;
  userId?: number;
  excludeRoles?: CompanyUserRole[];
  trainingTypes: TrainingTypesResponse;
  userRole?: string;
  canCreateTrainee?: boolean;
  setUserRole: (role: string) => void;
}

interface TemplateFormValues {
  name: string;
  email: string;
  trainingTypeId: number;
  allowSkipTraining: boolean;
  allowSkipToRiskAssessment: boolean;
  role: CompanyUserRole;
  typeOfTrainings?: TypeOfTraining[];
}

const FormContainer = styled(Form)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grayscale.moonGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .ant-form-item {
    width: 100%;

    .ant-select {
      width: 100%;
      .ant-select-selector {
        border-color: transparent;
        min-height: 40px;
        padding-top: 6px;
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
    }
  }
`;

export const FormCreateUser: FC<FormCreateUserProps> = ({
  userForm,
  companyId,
  editingProfile,
  editOther,
  userId,
  excludeRoles = [],
  trainingTypes,
  userRole,
  canCreateTrainee,
  setUserRole,
}) => {
  const dispatch = useDispatch();

  const [typeOfTrainingOptions, setTypeOfTrainingOptions] = useState<
    TypeOfTrainingOption[]
  >([]);
  const [userRoleOptions, setUserRoleOptions] = useState<UserRoleOption[]>([]);

  const handleSubmit = (values: TemplateFormValues) => {
    if (!values) {
      return;
    }

    const isInsert = !userId;

    if (isInsert) {
      const userFormInput: UserInput = {
        ...values,
        companyId: Number(companyId),
        isActive: true,
      };

      dispatch(userSaveRequest(userFormInput));
    } else {
      const userFormInput: UserUpdateInput = {
        ...values,
        id: userId,
      };

      dispatch(userUpdateRequest(userFormInput, undefined, !editOther));
    }
  };

  useEffect(() => {
    const typeOfTrainings: TypeOfTrainingOption[] = Object.values(
      TypeOfTraining,
    ).map((typeOfTraining) => ({
      value: typeOfTraining,
      displayValue: TYPE_OF_TRAINING_TEXTS[typeOfTraining],
    }));
    setTypeOfTrainingOptions(typeOfTrainings);
  }, []);

  useEffect(() => {
    const newUserRoleOptions = mapUserRoleOptions(excludeRoles);
    setUserRoleOptions(newUserRoleOptions);
  }, [excludeRoles]);

  return (
    <FormContainer
      form={userForm}
      onFinish={(values) => {
        handleSubmit(values as TemplateFormValues);
      }}
    >
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please input the user name',
          },
        ]}
      >
        <TextInput placeholder="Name" />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            whitespace: true,
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: 'Please input the user email',
          },
        ]}
      >
        <TextInput placeholder="Email" type="email" />
      </Form.Item>

      {!editingProfile && (
        <>
          <Form.Item
            name="role"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please select an assessment type',
              },
            ]}
          >
            <Select placeholder="Select a user role" onChange={setUserRole}>
              {userRoleOptions.map(
                ({ value, displayValue }: UserRoleOption) => (
                  <Select.Option value={value} key={value}>
                    {displayValue}
                  </Select.Option>
                ),
              )}
            </Select>
          </Form.Item>
          {userRole === CompanyUserRole.company_moderator && (
            <Form.Item
              name="typeOfTrainings"
              rules={[
                {
                  required: true,
                  message: 'Please select at least one training type',
                },
              ]}
            >
              <Select placeholder="Select a training type" mode="multiple">
                {typeOfTrainingOptions.map(({ value, displayValue }) => (
                  <Select.Option value={value} key={value}>
                    {displayValue}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {(userRole === CompanyUserRole.trainee ||
            userRole === CompanyUserRole.officer) && (
            <>
              <Form.Item name="trainingTypeId" rules={[{ required: false }]}>
                <Select placeholder="Select the training type">
                  {trainingTypes.map((trainingType) => (
                    <Select.Option
                      value={trainingType.id}
                      key={trainingType.id}
                    >
                      {trainingType.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="allowSkipTraining"
                valuePropName="checked"
                rules={[{ required: false }]}
              >
                <Checkbox>Bypass quizzes (i.e straight to exam)</Checkbox>
              </Form.Item>

              {userRole === CompanyUserRole.officer && (
                <Form.Item
                  name="allowSkipToRiskAssessment"
                  valuePropName="checked"
                  rules={[{ required: false }]}
                >
                  <Checkbox>Skip to Risk Assessment</Checkbox>
                </Form.Item>
              )}

              {userRole === CompanyUserRole.trainee && !canCreateTrainee && (
                <>
                  <div style={{ color: 'red', fontStyle: 'italic' }}>
                    Warning: There is not an open training period. Please create
                    one first.
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </FormContainer>
  );
};
