import React, { FC, useState, useEffect } from 'react';
import { Modal, Tabs, Divider, Form, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Toaster } from 'react-hot-toast';

import { TopNavbar } from 'src/components/TopNavbar';
import { SearchInput } from 'src/components/SearchInput';
import {
  BasicUser,
  RootState,
  TrainingPeriodListingElement,
  UserRole,
} from 'src/store/types';
import {
  getCompanyFromState,
  getCompanyLoaderFromState,
  getUserLoader,
  getUserFromCompanyById,
  getTrainingPeriodListingLoader,
  getTrainingPeriodListingResponse,
  getCurrentTrainingPeriod,
  getTrainingTypes,
  hasAnOpenTrainingPeriod,
  getUserSummary,
  getCompanyUsersSize,
} from 'src/store/selectors';
import { Button } from 'src/components/Button';
import { SlidingPanel } from 'src/components/SlidingPanel';
import { useHistory, useParams } from 'react-router-dom';
import { companyLoadRequest } from 'src/store/actions/company';
import {
  FormCreateUser,
  UserTrainingSummaryListingTable,
  FormCreateMultipleUsers,
} from 'src/features/user/components';
import { CompanyInfoCard } from 'src/features/risk-assessment/components/CompanyInfoCard';
import {
  TrainingPeriodConfigForm,
  TrainingPeriodListingTable,
} from 'src/features/learning/components';
import actions from 'src/store/actions';
import { confirmDialog } from 'src/shared/utils';
import { COMPANY_GLOBAL_ROLES } from 'src/constants/user';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import { RoutesEnum } from 'src/shared/enums';

interface RouteParams {
  id: string;
}

enum CreationType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
  UPDATE_SINGLE = 'UPDATE_SINGLE',
}

const { TabPane } = Tabs;

const ListContainer = styled.div`
  max-height: 60vh;
  min-height: 30vh;
  overflow: auto;
  border-radius: 8px;
`;

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

const TabContainer = styled(Tabs)`
  margin-top: 22px;
  padding-bottom: 48px;
`;

export const CompanyProfilePage: FC<unknown> = () => {
  const dispatch = useDispatch();
  const { isCompanyModerator, isOfficer, user } = useAuth();

  const [valueToSearch, setValueToSearch] = useState<string>('');
  const [formPanelVisible, setFormPanelVisible] = useState<boolean>(false);
  const [formPanelTitle, setFormPanelTitle] = useState<string>('');
  const [creationType, setCreationType] = useState<CreationType>(
    CreationType.SINGLE,
  );
  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(
    undefined,
  );
  const [formPanelLoading, setFormPanelLoading] = useState<boolean>(false);
  const [isUsersTab, setIsUsersTab] = useState(true);
  const [creationModalVisible, setCreationModalVisible] = useState(false);
  const [userRole, setUserRole] = useState<string | undefined>(undefined);
  const [trainingPeriodToEdit, setTrainingPeriodToEdit] =
    useState<TrainingPeriodListingElement>();
  const [userForIndividualTraining, setUserForIndividualTraining] = useState<
    BasicUser | undefined
  >(undefined);

  const { replace } = useHistory();
  const { id } = useParams<RouteParams>();

  const { company: userCompany } = user;

  const userCompanyId: number | null = userCompany?.id ?? null;
  const paramCompanyId: number = id ? Number(id) : 0;
  const companyId = userCompanyId ?? paramCompanyId;

  const company = useSelector(getCompanyFromState);
  const companyLoader = useSelector(getCompanyLoaderFromState);
  const userLoader = useSelector(getUserLoader);
  const selectedUser = useSelector((state: RootState) =>
    getUserFromCompanyById(state, selectedUserId),
  );
  const companyUsersSize = useSelector((state: RootState) =>
    getCompanyUsersSize(state),
  );
  const companyUsersSummaries = useSelector((state: RootState) =>
    getUserSummary(state, valueToSearch),
  );
  const trainingPeriodListingLoader = useSelector(
    getTrainingPeriodListingLoader,
  );
  const trainingPeriodListingResponse = useSelector(
    getTrainingPeriodListingResponse,
  );
  const currentTrainingPeriod = useSelector(getCurrentTrainingPeriod);
  const trainingTypes = useSelector(getTrainingTypes);
  const openTrainingPeriod = useSelector(hasAnOpenTrainingPeriod);

  useEffect(() => {
    const isFetchedUser = Boolean(user.id);
    if (companyId) {
      dispatch(companyLoadRequest(companyId));
      if (isCompanyModerator || isOfficer) {
        dispatch(actions.trainingTypesLoadRequest());
        dispatch(actions.trainingPeriodListingLoadRequest(companyId));
      }
    }
    if (!companyId && isFetchedUser) {
      replace(RoutesEnum.DASHBOARD);
    }
  }, [companyId, user, isCompanyModerator]);

  useEffect(() => {
    dispatch(actions.userSummaryLoadRequest(companyId));
  }, [companyId]);

  useEffect(() => {
    if (creationModalVisible && currentTrainingPeriod) {
      setCreationModalVisible(false);
      setTrainingPeriodToEdit(undefined);
    }
  }, [currentTrainingPeriod]);

  useEffect(() => {
    if (userLoader.completed) {
      setFormPanelVisible(false);
      setFormPanelLoading(false);
      userForm.resetFields();
    }
  }, [userLoader.completed, companyLoader.completed]);

  useEffect(() => {
    if (companyLoader.completed) {
      setFormPanelVisible(false);
      setFormPanelLoading(false);
      multipleUsersForm.resetFields();
    }
  }, [companyLoader.completed]);

  useEffect(() => {
    if (selectedUser) {
      userForm.setFieldsValue({
        ...selectedUser,
        trainingTypeId: selectedUser.trainingTypeId,
      });
      setUserRole(selectedUser.role);
    }
  }, [selectedUser]);

  const [userForm] = Form.useForm();
  const [multipleUsersForm] = Form.useForm();

  const handleSaveButtonPress = () => {
    if (
      creationType === CreationType.SINGLE ||
      creationType === CreationType.UPDATE_SINGLE
    ) {
      userForm.submit();
    } else {
      multipleUsersForm.submit();
    }
  };

  const showFormPanel = (creationType: CreationType) => {
    setCreationType(creationType);
    setFormPanelTitle(
      creationType === CreationType.SINGLE
        ? 'Add new user'
        : 'Add multiple users',
    );
    setSaveButtonEnabled(creationType === CreationType.SINGLE);
    setFormPanelVisible(true);
  };

  const showEditUserPanel = (userId: number) => {
    if (userId === selectedUserId) {
      userForm.setFieldsValue({
        ...selectedUser,
        trainingTypeId: selectedUser.trainingTypeId,
      });
    } else {
      setSelectedUserId(userId);
    }
    setCreationType(CreationType.UPDATE_SINGLE);
    setFormPanelTitle('Edit user');
    setSaveButtonEnabled(true);
    setFormPanelVisible(true);
  };

  const handleOnEditPeriod = (period: TrainingPeriodListingElement) => {
    setTrainingPeriodToEdit(period);
    setCreationModalVisible(true);
  };

  const handleOnManualCompletion = (period: TrainingPeriodListingElement) => {
    confirmDialog({
      title: 'Warning: Manual Training Period Completion',
      text: 'Training periods close automatically at the end of the calendar year. By closing it now you will end the current training period. Are you sure you want to continue?',
      okText: 'Close training period',
      onOk: () => {
        dispatch(actions.trainingPeriodCompleteRequest(period.id));
      },
    });
  };

  const handleIndividualTrainingRelease = (user: BasicUser) => {
    setUserForIndividualTraining(user);
    setCreationModalVisible(true);
  };

  const canCreateTrainee =
    !!openTrainingPeriod && userRole === UserRole.trainee;
  const canCreateUsers = companyUsersSize < company.licenses!;
  const licensesRemainder =
    (company.licenses || 0) - companyUsersSize < 0
      ? 0
      : (company.licenses || 0) - companyUsersSize;

  const extraOptions = isOfficer ? (
    <>
      <Button
        onClick={() => {
          setCreationModalVisible(true);
        }}
        title={'Release Training'}
        disabled={
          // filter non-individual training periods
          trainingPeriodListingResponse
            .filter((training) => training.user === null)
            .some((period: any) => period.status === 'OPEN')
        }
        key={1}
        loading={companyLoader?.loading}
      />
      {isUsersTab && (
        <Button
          onClick={() => {
            if (isUsersTab) return showFormPanel(CreationType.SINGLE);
          }}
          title={'Add new user'}
          key={2}
          disabled={company ? !canCreateUsers : false}
          loading={companyLoader?.loading}
        />
      )}
      {isUsersTab && (
        <Button
          onClick={() => showFormPanel(CreationType.MULTIPLE)}
          title="Add multiple users"
          key={3}
          disabled={company ? !canCreateUsers : false}
          loading={companyLoader?.loading}
        />
      )}
    </>
  ) : null;

  const isCompanyFetched = Boolean(company.id);
  const canSave = canCreateTrainee || userRole !== UserRole.trainee;

  const onCloseModalOfTrainingPeriod = () => {
    setCreationModalVisible(false);
    setTrainingPeriodToEdit(undefined);
    setUserForIndividualTraining(undefined);
  };

  return (
    <>
      <TopNavbar
        title={company.name || ''}
        prevRoute={
          userCompanyId ? null : { name: 'Companies', url: '/companies' }
        }
        extraOptions={isOfficer && extraOptions}
      />
      <PageContentContainer>
        {!!company && !canCreateUsers && (
          <Alert
            message="There are no more training licenses in your account. Please contact Total HIPAA to purchase additional training licenses."
            type="warning"
            showIcon
          />
        )}
        {isCompanyFetched && (
          <CompanyInfoCard
            company={company}
            licensesRemainder={licensesRemainder}
          />
        )}
        {isOfficer && (
          <>
            <Divider />
            <TabContainer
              type="line"
              size="large"
              onChange={(e) => setIsUsersTab(e === 'users-tab')}
            >
              {isOfficer && (
                <TabPane tab="Officers & Trainees" key="users-tab">
                  <SearchInput
                    onChange={(e) => {
                      setValueToSearch(e.target.value);
                    }}
                    autoComplete="off"
                    placeholder="User name/email"
                  />
                  <ListContainer>
                    <UserTrainingSummaryListingTable
                      users={companyUsersSummaries}
                      companyTrainingType={company.trainingType?.name}
                      onEdit={showEditUserPanel}
                      onReleaseIndividualTraining={
                        handleIndividualTrainingRelease
                      }
                      trainingPeriods={trainingPeriodListingResponse}
                    />
                  </ListContainer>
                </TabPane>
              )}
              {isOfficer && (
                <TabPane
                  tab="Training Periods"
                  key="periods-tab"
                  disabled={trainingPeriodListingLoader.loading}
                >
                  <ListContainer>
                    <TrainingPeriodListingTable
                      onEdit={handleOnEditPeriod}
                      onManualCompletion={handleOnManualCompletion}
                      dataSource={trainingPeriodListingResponse}
                    />
                  </ListContainer>
                </TabPane>
              )}
            </TabContainer>
          </>
        )}
        {isOfficer && (
          <SlidingPanel
            title={formPanelTitle}
            visible={formPanelVisible}
            saveLabel={
              saveButtonEnabled
                ? creationType === CreationType.UPDATE_SINGLE
                  ? 'Update'
                  : 'Save'
                : ''
            }
            onClose={() => {
              setFormPanelVisible(false);
              userForm.resetFields();
            }}
            onSave={handleSaveButtonPress}
            loading={formPanelLoading}
            enabled={canSave}
          >
            {creationType === CreationType.SINGLE ||
            creationType === CreationType.UPDATE_SINGLE ? (
              <FormCreateUser
                userForm={userForm}
                companyId={companyId}
                userId={selectedUserId}
                editOther={creationType === CreationType.UPDATE_SINGLE}
                excludeRoles={COMPANY_GLOBAL_ROLES}
                trainingTypes={trainingTypes}
                userRole={userRole}
                canCreateTrainee={canCreateTrainee}
                setUserRole={setUserRole}
              />
            ) : (
              <FormCreateMultipleUsers
                multipleUsersForm={multipleUsersForm}
                companyId={companyId}
                setSaveButtonEnabled={setSaveButtonEnabled}
              />
            )}
          </SlidingPanel>
        )}
      </PageContentContainer>
      {(isCompanyModerator || isOfficer) && (
        <Modal
          title={
            userForIndividualTraining
              ? trainingPeriodToEdit
                ? 'Edit individual training period'
                : 'Set individual training period'
              : trainingPeriodToEdit
                ? 'Edit period'
                : 'New period'
          }
          bodyStyle={{ backgroundColor: 'whitesmoke' }}
          visible={creationModalVisible}
          onCancel={onCloseModalOfTrainingPeriod}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <TrainingPeriodConfigForm
            companyId={companyId}
            trainingPeriod={trainingPeriodToEdit}
            user={userForIndividualTraining}
          />
        </Modal>
      )}
      <Toaster />
    </>
  );
};
