import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCurrentTrainingPeriod } from 'src/store/selectors';
import { TrainingPeriodResponse } from 'src/store/types';

export const useTraining = (): {
  isTrainingPeriodReleaseOverlay: boolean;
  currentTrainingPeriod: TrainingPeriodResponse;
} => {
  const currentTrainingPeriod = useSelector(getCurrentTrainingPeriod);
  const location = useLocation();
  const canTrainingBeReleasedToday: boolean =
    new Date(currentTrainingPeriod?.releaseDate) <= new Date();
  const isTrainingPeriodReleaseOverlay: boolean = !currentTrainingPeriod
    ? true && location.pathname.includes('my-training')
    : !canTrainingBeReleasedToday && location.pathname.includes('my-training');

  return { isTrainingPeriodReleaseOverlay, currentTrainingPeriod };
};
