import React, { FC } from 'react';
import styled from 'styled-components';
import * as crypto from 'crypto';

interface TextWrapperProps {
  value: string;
  classNames: string[];
  lineNumber: number;
  refKeysByLine: { [k: number]: string };
  handleStartNewDiscussion: (refKey: string) => void;
  setSelectedRefKey: (refKey?: string) => void;
  setSelectedLine: (lineNumber?: number) => void;
  setHoveredLine: (lineNumber?: number) => void;
  selectedLine?: number;
  selectedRefKey?: string;
  hoveredLine?: number;
}

const Text = styled.span`
  &.center {
    text-align: center;
  }

  cursor: pointer;

  &.with-thread {
    background-color: rgba(255, 221, 52, 0.4);
  }

  &.is-active {
    background-color: rgba(255, 221, 52, 1);
  }

  &.is-hover {
    background-color: rgba(255, 221, 52, 0.2);

    &.with-thread {
      background-color: rgba(255, 221, 52, 0.9);
    }
  }
`;

export const TextWrapper: FC<TextWrapperProps> = ({
  value,
  classNames,
  lineNumber,
  refKeysByLine,
  handleStartNewDiscussion,
  setSelectedRefKey,
  setSelectedLine,
  setHoveredLine,
  selectedLine,
  selectedRefKey,
  hoveredLine,
}) => {
  const refKey = refKeysByLine[lineNumber];

  const openThread = (): void => {
    setSelectedLine(lineNumber);

    if (refKey) {
      setSelectedRefKey(refKey);
    } else {
      const newRefKey = crypto
        .createHash('sha256')
        .update(`${value}(${lineNumber})`)
        .digest('hex');

      handleStartNewDiscussion(newRefKey);
    }
  };

  const textClassNames = [...classNames];

  if (refKey) textClassNames.push('with-thread');

  if (
    selectedLine === lineNumber ||
    (selectedRefKey && selectedRefKey === refKey)
  ) {
    textClassNames.push('is-active');
  }

  if (hoveredLine === lineNumber) {
    textClassNames.push('is-hover');
  }

  return (
    <Text
      id={refKey}
      className={textClassNames.join(' ')}
      onClick={openThread}
      dangerouslySetInnerHTML={{ __html: value }}
      onMouseEnter={() => setHoveredLine(lineNumber)}
      onMouseLeave={() => setHoveredLine(undefined)}
    />
  );
};
