import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { AutoComplete } from 'antd';
import { RootState } from 'src/store/types';
import { Tooltip } from 'src/components/Tooltip';
import { CheckCircleTwoTone } from 'src/theme/icons';
import { getCompanyUsers } from 'src/store/selectors';
import * as S from './Styles';

export interface AssignButtonProps {
  hide: boolean;
  buttonTooltip: string;
  autoCompleteTitle: string;
  modalTitle: string;
  isQuestion?: boolean;
  onOk: (selectedAssigneeId?: number) => void;
}

type SelectedUser = { value: string; id: number };

const AssignButton: FC<AssignButtonProps> = ({
  hide,
  buttonTooltip,
  modalTitle,
  autoCompleteTitle,
  isQuestion,
  onOk,
}) => {
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [selectedAssignee, setSelectedAssignee] = useState<SelectedUser>();
  const companyUsers = useSelector((state: RootState) =>
    getCompanyUsers(state, undefined, false),
  );

  return (
    <span>
      {!hide && (
        <S.UserAssignerContainer onClick={() => setDialogVisible(true)}>
          <span className="assignButtonIcon">
            <Tooltip text={buttonTooltip}>
              <S.QuestionUserAssigner />
            </Tooltip>
          </span>
          <span className="assignButtonText">
            {isQuestion ? 'Assign Question' : 'Assign Risk Section'}
          </span>
        </S.UserAssignerContainer>
      )}
      <S.StyledModal
        visible={dialogVisible}
        title={modalTitle}
        onOk={() => {
          onOk(selectedAssignee?.id);
          setDialogVisible(false);
        }}
        onCancel={() => setDialogVisible(false)}
      >
        {!isQuestion && (
          <p>
            Assign this risk assessment section to one of your teammates and
            we’ll let them know. You can change this any time.
          </p>
        )}
        {isQuestion && (
          <p>
            Assign this risk assessment question to one of your teammates and
            we’ll let them know. You can change this any time.
          </p>
        )}
        <S.AutocompleteTitleContainer>
          {!isQuestion ? (
            <b>Assign {autoCompleteTitle} to:</b>
          ) : (
            <b>"{autoCompleteTitle}"</b>
          )}
        </S.AutocompleteTitleContainer>
        <AutoComplete
          style={{ width: '100%' }}
          allowClear
          options={Object.values(companyUsers).map((companyUser: any) => {
            return {
              value: companyUser.name,
              id: companyUser.id,
            };
          })}
          placeholder="Assign to:"
          onSelect={(value, option) =>
            setSelectedAssignee({ value, id: option.id })
          }
          onDeselect={() => setSelectedAssignee(undefined)}
          onClear={() => setSelectedAssignee(undefined)}
          filterOption={(inputValue, option) =>
            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        />
        {selectedAssignee && (
          <p>
            <CheckCircleTwoTone twoToneColor="#52c41a" />
            <span>Assigned to: </span>
            <a>{selectedAssignee.value}</a>
          </p>
        )}
      </S.StyledModal>
    </span>
  );
};

export default AssignButton;
