import moment from 'moment';
import React from 'react';

import { Link } from 'react-router-dom';
import { SimpleUserAvatar } from 'src/features/user/components';
import { NotificationFromResponse, NotificationTypes } from 'src/store/types';
import { NotificationListItemData } from './NotificationListingTable';

const formatNotificationBody = (
  notification: NotificationFromResponse,
): React.ReactNode => {
  const where = (
    <Link to={notification.location}>
      {notification.locationName?.toLowerCase()}
    </Link>
  );

  const who = notification.from ? (
    <>
      {SimpleUserAvatar({ user: notification.from, size: 26 })}{' '}
      <b>{notification.from.name}</b>
    </>
  ) : (
    `Somebody`
  );

  switch (notification.type) {
    case NotificationTypes.comment: {
      return (
        <>
          {'There is a new comment in '} {where}
          {'.'}
        </>
      );
    }
    case NotificationTypes.mention: {
      return (
        <>
          {who}
          {' mentioned you in '}
          {where}
          {'.'}
        </>
      );
    }
    case NotificationTypes.documentDraftInClientReview: {
      return (
        <>
          {'Your '}
          <Link to={notification.location}>Policies and Procedures</Link>
          {' have been created by Total HIPAA and it is ready for your review.'}
        </>
      );
    }
    case NotificationTypes.riskAssessmentEnabled: {
      return (
        <>
          {'Your '}
          <Link to={notification.location}>Risk Assessment</Link>
          {' has been unlocked!'}
        </>
      );
    }
    case NotificationTypes.riskReportInAdminReview: {
      return notification.from?.company?.name ? (
        <>
          <b>{notification.from.company?.name}</b>
          {' has marked the '}
          <Link to={notification.location}>Risk Assessment Report</Link>
          {' as accepted.'}
        </>
      ) : (
        <>
          {`A `}
          <Link to={notification.location}>Risk Assessment Report</Link>
          {' has been marked as accepted.'}
        </>
      );
    }
    case NotificationTypes.riskReportInClientReview: {
      return (
        <>
          {'Your '}
          <Link to={notification.location}>Risk Assessment Report</Link>
          {' is ready for your Review!'}
        </>
      );
    }
    case NotificationTypes.templateNeedsReview: {
      return (
        <>
          {'A template needs a review, please check the '}
          <Link to={'/templates'}>template listing</Link>
          {'.'}
        </>
      );
    }
    case NotificationTypes.newAssignee: {
      return (
        <>
          {who}
          {' assigned you a '}
          <Link to={notification.location}>Risk Assessment Question</Link>
          {'.'}
        </>
      );
    }
    case NotificationTypes.newOwnerAssignee: {
      return (
        <>
          {who}
          {' assigned you a '}
          <Link to={notification.location}>Risk Assessment Section</Link>
          {'.'}
        </>
      );
    }
    default: {
      return (
        <>
          {'Your attention is required '}
          <Link to={notification.location}>here</Link>
          {'.'}
        </>
      );
    }
  }
};

export const dataMapper = (
  notificationsData: NotificationFromResponse[],
): NotificationListItemData[] => {
  return notificationsData.map((notification) => {
    const { createdAt, id, type, pending, archived, location } = notification;
    return {
      id,
      type,
      pending,
      archived,
      body: formatNotificationBody(notification),
      createdAt: `${moment.duration(moment(new Date()).diff(moment(createdAt))).humanize()} ago`,
      location,
    };
  });
};
